import { Routes, Route } from "react-router-dom"
import { EventValidationPage } from "./pages/EventValidationPage"
import { HomePage } from "./pages/HomePage"
import { AvisLegalPage } from "./pages/AvisLegalPage"
import { PoliticaPrivacitatPage } from "./pages/PoliticaPrivacitatPage"
import { SupportPage } from "./pages/SupportPage"
import { EventPage } from "./pages/EventPage"
import { LayoutPage } from "./pages/LayoutPage"
import { AppPage } from "./pages/AppPage"
import { CalendariPage } from "./pages/CalendariPage"
import { AvuiPage } from "./pages/AvuiPage"


function App() {
  return (
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          <Route path="eventValidation" element={ <EventValidationPage/> } />
        </Route>
        <Route index element={ <HomePage/> } />
        <Route path="support" element={ <SupportPage/> } />
        <Route path="app" element={ <AppPage/> } />
        <Route path="calendari" element={ <CalendariPage/> } />
        <Route path="avui" element={ <AvuiPage/> } />
        <Route path="avis-legal" element={ <AvisLegalPage/> } />
        <Route path="politica-privacitat" element={ <PoliticaPrivacitatPage/> } />
        <Route path="activitat/:eventId" element={<EventPage />} />
        <Route path="*" element={ <HomePage/> }  />
      </Routes>
  )
}

export default App
