import {AppBar, Toolbar, Typography, Box} from '@mui/material';
import { Outlet } from "react-router-dom";

export const LayoutPage = () => {
  return (
    <Box>
        <AppBar position="static">
            <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                EVENTS
            </Typography>
            </Toolbar>
        </AppBar>
        <Outlet />
    </Box>
  );
};
