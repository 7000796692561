import { StringDictionary } from "./types";
export const CODES_TO_MONTHS: StringDictionary = {
    0 : "de gener",
    1 : "de febrer",
    2 : "de març",
    3 : "d'abril",
    4 : "de maig",
    5 : "de juny",
    6 : "de juliol",
    7 : "d'agost",
    8 : "de setembre",
    9 : "d'octubre",
    10 : "de novembre",
    11 : "de desembre",
};
export const MONTH_TO_TEXT: StringDictionary = {
    0 : "de gener",
    1 : "de febrer",
    2 : "de març",
    3 : "d'abril",
    4 : "de maig",
    5 : "de juny",
    6 : "de juliol",
    7 : "d'agost",
    8 : "de setembre",
    9 : "d'octubre",
    10 : "de novembre",
    11 : "de desembre",
}

export const CATEGORIES_DICTIONARY: StringDictionary = {
    "musica" : "Música",
    "teatre-i-dansa" : "Teatre i dansa",
    "exposicions": "Exposicions",
    "lletres": "Lletres",
    "conferencies": "Conferències",
    "festes-i-tradicions": "Festes i tradicions",
    "cinema" : "Cinema",
    "activitats-infantils" : "Activitats infantils",
    "esdeveniments-esportius" : "Esdeveniments esportius",
    "mes-cultura": "Més cultura",
    "cursos-i-tallers": "Cursos i tallers",
    "fires-i-mercats": "Fires i mercats",
    "la-cultura-no-s-atura": "La cultura no s'atura"
}

export const IOS_DOWNLOAD_LINK = "https://apps.apple.com/es/app/quedand/id6502789863?l=ca"
export const ANDROID_DOWNLOAD_LINK = "https://play.google.com/store/apps/details?id=com.mcamp.quedand&hl=ca"
export const CONTACT_EMAIL = "quedand.app@gmail.com"