import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from "dayjs/plugin/updateLocale";
import { collection, getDocs, query, where, doc, updateDoc, addDoc, getDoc, setDoc, deleteDoc } from 'firebase/firestore/lite';
import db from '../../firebase';
import {Typography, Box, TextField, Stack, IconButton, Button, Link, Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, Card, CardContent, Radio, RadioGroup} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CATEGORIES_DICTIONARY } from '../../utils/Constants';
import { DateTimePicker } from '@mui/x-date-pickers';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1
});

interface esdevenimentDB {
  id?: string;
  title?: string;
  desc?: string;
  image?: string;
  price?: string;
  location?:string;
  category?: string;
  date: dateObjectDB;
  mes_info?: string;
  info?: string;
  url?: string;
  buy_link?: string;
  docs?: Array<string>  
  contact?: {
    whats?: string;
    tel?: string;
    email?: string;
    link?: string;
    address?: string;
    buy_link?: string;
  }
  status?: string;
  published_date?: Date;
  modified_date?: Date;
  group?: number;
  original?: boolean;
  multiDay?: boolean;
}

interface esdeveniment {
  id?: string;
  title?: string;
  desc?: string;
  image?: string;
  price?: string;
  location?:string;
  category?: string;
  date: dateObject;
  mes_info?: string;
  info?: string;
  info_entrades?: string;
  url?: string;
  buy_link?: string;
  docs?: Array<string>  
  contact?: {
    whats?: string;
    tel?: string;
    email?: string;
    link?: string;
    address?: string;
    buy_link?: string;
  }
  status?: string;
  published_date?: Date;
  modified_date?: Date;
  group?: number;
  original?: boolean;
  multiDay?: boolean;
}

interface dateObject {
  string?:Array<string> ;
  init?: Dayjs;
  end?: Dayjs;
}

interface dateObjectDB {
  string?:Array<string> ;
  init?: number;
  end?: number;
}

export const EventValidationPage = () => {
  
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<esdeveniment[]>([]);
  const [currentEvent, setCurrentEvent] = useState({} as esdeveniment);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  console.log(currentEvent);
  console.log(data)

  useEffect(() => {
    const getEvents = async () => {
        const q = query(collection(db, "events"), where("status", "==", "TO REVIEW"));
        try {
          const eventsSnapshot = await getDocs(q);
          const eventsList = eventsSnapshot.docs.map(doc => {
            let dateObj = {} as dateObject;
            if (doc.data().date.init !== undefined) {
              const initDate: Dayjs = dayjs.unix(doc.data().date.init);
              dateObj.init = initDate;
            }
            if (doc.data().date.end !== undefined) {
              const endDate: Dayjs = dayjs.unix(doc.data().date.end);
              dateObj.end = endDate;
            }
            if (doc.data().date.string !== undefined) {
              dateObj.string = doc.data().date.string;
            }
            return {...doc.data(), date: dateObj};
          });
          setData(eventsList);

          if (eventsList.length > 0) {
            if (Object.keys(currentEvent).length === 0) {
              setCurrentEvent(eventsList[0]);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    
    getEvents();
  }, []);

  function handleFormChange(newValue: string | null | Dayjs, prop: string) {
    if (prop !== undefined) {
      if (prop === "init" || prop === "end") {
        setCurrentEvent(prevState => ({
          ...prevState,
          date: {
              ...prevState.date,
              [prop]: newValue as Dayjs
            }
        }))
      } else if (prop === "whats" || prop === "tel" || prop === "email" || prop === "link" || prop === "address" || prop === "buy_link") {
        setCurrentEvent(prevState => ({
          ...prevState,
          contact: {
              ...prevState.contact!,
              [prop]: newValue
            }
        }))
      } else {
        setCurrentEvent({...currentEvent, [prop]: newValue});
      }
    }
  }

  const handleMultiDayEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEvent({...currentEvent, multiDay: event.target.checked});
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEvent({...currentEvent, category: (event.target as HTMLInputElement).value});
  };

  function deleteDate(dateRef: string) {
    setCurrentEvent(prevState => ({
      ...prevState,
      date: {
          ...prevState.date,
          [dateRef]: null
        }
    }))
  }

  function deleteEvent() {
    setOpenDeleteDialog(true)
  }

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  async function confirmDeleteEvent() {
    let eventUpdatesToSend: esdevenimentDB = {
      date: {string: currentEvent.date.string} as dateObjectDB,
    }
    if (currentEvent.status === "TO REVIEW") {
      eventUpdatesToSend.status = "DELETED"
      eventUpdatesToSend.published_date = new Date();
    }
    const docRef = doc(db, "events", currentEvent.id!);

    try {
      await updateDoc(docRef, {...eventUpdatesToSend}).then(function() {
        const eventsArray = [...data]
        eventsArray.shift()
  
        console.log("innnn");
        if (eventsArray.length > 0) {
          setCurrentEvent(eventsArray[0]);
        } else {
          setCurrentEvent({} as esdeveniment)
        }
        setOpenDeleteDialog(false);
        setData(current =>
          current.filter(event => {
            return currentEvent.id === event.id;
          }),
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function saveEvent(_event: React.MouseEvent<HTMLElement>, duplicate?: boolean) {
    let eventUpdatesToSend: esdevenimentDB = {
      title: currentEvent.title!.trimStart().trimEnd(),
      desc: currentEvent.desc!.trimStart().trimEnd(),
      image:currentEvent.image,
      price: currentEvent.price ? currentEvent.price.replace(/,/g, '.') : "",
      date: {string: currentEvent.date.string} as dateObjectDB,
      multiDay: currentEvent.multiDay === true ? currentEvent.multiDay : false,
      category: currentEvent.category,
    }
    if (currentEvent.date.init !== null && currentEvent.date.init !== undefined) {
      eventUpdatesToSend.date.init = currentEvent.date.init.unix()
    }
    if (currentEvent.date.end !== null && currentEvent.date.end !== undefined) {
      eventUpdatesToSend.date.end = currentEvent.date.end.unix()
    }
    if (currentEvent.status === "TO REVIEW") {
      eventUpdatesToSend.status = "PUBLISHED"
      eventUpdatesToSend.published_date = new Date();
    }
    if (currentEvent.contact !== undefined) {
      eventUpdatesToSend.contact = currentEvent.contact
      if (currentEvent.contact.address !== undefined) {
        eventUpdatesToSend.location = currentEvent.contact.address;
      }
      if (currentEvent.contact.whats !== undefined) {
        currentEvent.contact.whats = currentEvent.contact.whats.trimStart().trimEnd();
      }
      if (currentEvent.contact.tel !== undefined) {
        currentEvent.contact.tel = currentEvent.contact.tel.trimStart().trimEnd();
      }
      if (currentEvent.contact.email !== undefined) {
        currentEvent.contact.email = currentEvent.contact.email.trimStart().trimEnd();
      }
      if (currentEvent.contact.link !== undefined) {
        currentEvent.contact.link = currentEvent.contact.link.trimStart().trimEnd();
      }
      if (currentEvent.contact.buy_link !== undefined) {
        currentEvent.contact.buy_link = currentEvent.contact.buy_link.trimStart().trimEnd();
      }
    }
    if (currentEvent.buy_link) {
      eventUpdatesToSend.buy_link = currentEvent.buy_link
    }
    //IF PUBLISHED, update modified with current version (0,1...??)
    eventUpdatesToSend.modified_date = new Date();
    console.log(eventUpdatesToSend)
    console.log(currentEvent);
    if (duplicate) {
      const group  = Math.floor(Math.random() * (100000 - 1 + 1)) + 1
      console.log(group);
      eventUpdatesToSend.group = group;
      eventUpdatesToSend.original = true;
    }
    const docRef = doc(db, "events", currentEvent.id!);
    try {
      await updateDoc(docRef, {...eventUpdatesToSend}).then(function() {});
    } catch (error) {
      console.error(error);
    }
    if (duplicate) {
      let duplicatedEvent: esdevenimentDB = {...data[0], ...eventUpdatesToSend}
      if (duplicatedEvent.id !== undefined) {
        delete duplicatedEvent.id;
      }
      duplicatedEvent.status = "TO REVIEW"
      delete duplicatedEvent.original;

      //duplicate the saved event and load the duplicated one!
      try {
        await addDoc(collection(db, "events"), duplicatedEvent).then(function(duplicatedDoc) {
          console.log("Event duplicated");
          console.log(duplicatedDoc.id);
          setDocId(duplicatedDoc.id)
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      const eventsArray = [...data]
      console.log(eventsArray)
      console.log(typeof(eventsArray))
      eventsArray.shift()

      console.log("innnn");
      if (eventsArray.length > 0) {
        console.log("innnnn1")
        setCurrentEvent(eventsArray[0]);
      } else {
        console.log("innnnn2")
        setCurrentEvent({} as esdeveniment)
      }
      setData(current =>
        current.filter(event => {
          return eventUpdatesToSend.id === event.id;
        }),
      );
    }
  }

  async function setDocId(id: string) {
    try {
      const docRef = doc(db, "events", id);
      await setDoc(docRef, {id: id}, { merge: true }).then(function() {
        getDuplicatedEvent(id)
      });
    } catch (error) {
      console.error(error);
    } 
  }

  async function getDuplicatedEvent(id: string) {
    try {
      console.log(id)
      const docRef = doc(db, "events", id);
      const docSnap = await getDoc(docRef);
      console.log(docSnap.data());

      if (docSnap.data() !== undefined) {
        let dateObj = {} as dateObject;
        if (docSnap.data()!.date.init !== undefined) {
          const initDate: Dayjs = dayjs.unix(docSnap.data()!.date.init);
          dateObj.init = initDate;
        }
        if (docSnap.data()!.date.end !== undefined) {
          const endDate: Dayjs = dayjs.unix(docSnap.data()!.date.end);
          dateObj.end = endDate;
        }
        if (docSnap.data()!.date.string !== undefined) {
          dateObj.string = docSnap.data()!.date.string;
        }
        setCurrentEvent({...docSnap.data(), date: dateObj, id: id} as esdeveniment);
      }
  
    } catch (error) {
      console.error(error);
    } 
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Helmet>
            <meta content="noindex" name="robots" />
        </Helmet>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          TO REVIEW ({data.length})
        </Typography>
        {Object.keys(currentEvent).length !== 0 &&
          <Grid container>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              {currentEvent.group && currentEvent.original === undefined && <Alert severity="warning">Duplicated Event</Alert>}
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              <TextField
                fullWidth
                label="Title"
                value={currentEvent.title}
                onChange={(e) => handleFormChange(e.target.value, "title")}
              />
            </Grid>
            <Grid xs={12} sm={10} smOffset={1}>
              <RadioGroup
                  row
                  value={currentEvent.category}
                  onChange={handleRadioChange}
                >
                  {Object.entries(CATEGORIES_DICTIONARY).map(([categoryKey, categoryName], index) =>(
                    <FormControlLabel value={categoryName} control={<Radio />} label={categoryName} />  
                  ))} 
              </RadioGroup>
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              <Alert severity="error">Si gratüit deixar en blanc o posar 0, si preu posar número, sin escriure algo. MAI SÍMBOL €!!!.</Alert>
              <TextField
                fullWidth
                label="Price"
                value={currentEvent.price}
                onChange={(e) => handleFormChange(e.target.value, "price")}
              />
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid xs={12} sm={6} md>
                    <Button href={currentEvent.url!} target="_blank" variant="contained" fullWidth>Agenda.ad</Button>
                  </Grid>
                  <Grid xs={12} sm={6} md>
                    <Button href={"https://quedand.com/activitat/" + currentEvent.id} target="_blank" variant="contained" fullWidth>Quedand.com</Button>
                  </Grid>
                  {currentEvent.docs !== undefined &&
                    Object.entries(currentEvent.docs).map(([docIndex, docUrl]) => (
                      <Grid xs={12} sm={6} md key={docIndex}>
                        <Button href={"https://www.agenda.ad" + docUrl} target="_blank" variant="contained" fullWidth>Doc. adjunt {Number(docIndex+1)}</Button>
                      </Grid>
                    ))
                  }
              </Grid>
            </Grid>
            <Grid xs={6} sm={5} smOffset={1} sx={{ m:2 }}>
              <Stack direction="row" spacing={1}>
                <DateTimePicker ampm={false} label="Start" format="DD MMM YYYY, HH:mm" slotProps={{ textField: { fullWidth: true } }} value={currentEvent.date!.init || null}  onChange={(newValue) => handleFormChange(newValue, "init")}/>
                <IconButton aria-label="delete" onClick={()=>deleteDate("init")}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid xs={6} sm={5} sx={{ m:2 }}>
              <Stack direction="row" spacing={1}>
                <DateTimePicker ampm={false} label="End" format="DD MMM YYYY, HH:mm" slotProps={{ textField: { fullWidth: true } }} value={currentEvent.date!.end || null}  onChange={(newValue) => handleFormChange(newValue, "end")}/>
                <IconButton aria-label="delete" onClick={()=>deleteDate("end")}>
                <HighlightOffIcon />
                </IconButton>
              </Stack>
              <FormControlLabel control={<Checkbox checked={currentEvent.multiDay} onChange={handleMultiDayEvent}/>} label={<Typography >MultiDayEvent per exposicions <Box color="error.main">(Si multiday, posar hora 00:00)</Box></Typography>} />
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              {currentEvent.date!.string && <Typography>{currentEvent.date!.string.toString()}</Typography>}
            </Grid>
            <Grid xs={12} smOffset={1} sx={{ m:2 }}>
              <TextField
                label="Desc"
                multiline
                fullWidth
                value={currentEvent.desc}
                onChange={(e) => handleFormChange(e.target.value, "desc")}
              />
            </Grid>
            {currentEvent.desc !== undefined &&
              <Grid xs={12} smOffset={1} sx={{ m:2 }}>
                <Card sx={{bgcolor: "warning.light"}}>
                  <CardContent>
                    <Typography sx={{ whiteSpace: 'pre-wrap'}}>
                      {currentEvent.desc.replace(/\\n/g,'\n\n')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            }
            <Grid xs={12} sm smOffset={1} sx={{ m:2 }}>
              <TextField
                label="Image"
                fullWidth
                value={currentEvent.image}
                onChange={(e) => handleFormChange(e.target.value, "image")}
              />
            </Grid>
            <Grid xs={12} sm  sx={{ m:2 }}>
              <img src={currentEvent.image} width="100%" alt="event image"/>
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              {currentEvent.mes_info && <Typography>{currentEvent.mes_info!.toString()}</Typography>}
              {currentEvent.info_entrades && <Typography>{currentEvent.info_entrades!.toString()}</Typography>}
              {currentEvent.info && <Typography>{currentEvent.info!.toString()}</Typography>}
              <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid xs={12} sm={6} md>
                  <Stack direction="column" spacing={1}>
                    <Alert severity="error">Posar 376 SEMPRE!!!</Alert>
                    <TextField
                      fullWidth
                      label="Whatsapp"
                      value={currentEvent.contact?.whats || ''}
                      onChange={(e) => handleFormChange(e.target.value, "whats")}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} sm={6} md>
                  <TextField
                    fullWidth
                    label="Tel"
                    value={currentEvent.contact?.tel || ''}
                    onChange={(e) => handleFormChange(e.target.value, "tel")}
                  />
                </Grid>
                <Grid xs={12} sm={6} md>
                  <TextField
                    fullWidth
                    label="Email"
                    value={currentEvent.contact?.email || ''}
                    onChange={(e) => handleFormChange(e.target.value, "email")}
                  />
                </Grid>
                {/* {Do we need link in here???} */}
                <Grid xs={12} sm={6} md>
                  <TextField
                    fullWidth
                    label="Link"
                    value={currentEvent.contact?.link || ''}
                    onChange={(e) => handleFormChange(e.target.value, "link")}
                  />
                </Grid>
                {currentEvent.buy_link !== undefined && currentEvent.buy_link !== "" &&
                  <Grid xs={12} sm={6} md>
                    <TextField
                      fullWidth
                      label="Buy link"
                      value={currentEvent.contact?.buy_link || ''}
                      onChange={(e) => handleFormChange(e.target.value, "buy_link")}
                    />
                    <Box>
                      <Link href={"https://www.agenda.ad" + currentEvent.buy_link} target="_blank">Compra link</Link>
                      <Typography variant="subtitle2" gutterBottom>
                        NOTA: Copiar enllaç un cop obert
                      </Typography>
                    </Box>
                  </Grid>
                }
                {/* {Do we need addresss???Idem lat/lng????} */}
                <Grid xs={12} sm={6} md>
                  <TextField
                    fullWidth
                    label="Address"
                    value={currentEvent.contact?.address || ''}
                    onChange={(e) => handleFormChange(e.target.value, "address")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={10} smOffset={1} sx={{ m:2 }}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid xs>
                  <Button onClick={deleteEvent} variant="contained" color="error" fullWidth>Esborrar</Button>
                </Grid>
                <Grid xs>
                  <Button onClick={(e) => saveEvent(e, true)} variant="contained" color="secondary" fullWidth disabled={currentEvent.multiDay === true}>Duplicar</Button>
                  <Typography>Never duplicate a multiDayEvent!</Typography>
                </Grid>
                <Grid xs>
                  <Button onClick={(e) => saveEvent(e)} fullWidth variant="contained">Save</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          Esborrar event
        </DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Estas segur?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={confirmDeleteEvent} color="error">
            Esborrar
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
