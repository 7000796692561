import {Typography, Box }from "@mui/material";
import { DownloadAppHeader } from "../../components/DownloadAppHeader";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export const AvisLegalPage = () => {
  return (
    <Grid container spacing={2}>
      <DownloadAppHeader />
      <Box sx={{p:2, spacing:2}}>
        <Typography variant="h4">Avis legal</Typography>
        <Typography>Les presents termes i condicions generals d’ús i informació legal (d’ara endavant, Condicions Generals) regulen l’ús i accés al lloc web www.quedand.com i la corresponent aplicació mòbil, a través del qual es posa a la disposició dels usuaris (d’ara endavant Usuari o Usuaris) el lloc web i l’aplicació mòbil QuedAND (d’ara endavant denominades conjuntament, la Plataforma).</Typography>
        <Typography>L'accés a aquest lloc web i a la informació continguda comporta l'acceptació de les condicions previstes en aquest avís legal. Per aquest motiu, us recomanem que llegiu atentament el contingut d'aquest avís si voleu accedir i utilitzar la informació i els serveis que s'ofereixen des d'aquesta plataaforma.</Typography>
        <Typography>L'usuari, per mitjà de les diverses àrees que formen part de la plataforma, pot visitar i obtenir informació de diferents esdeveniments.</Typography>
        <Typography>La plataforma no es fa responsable de l'exactitud i de la vigència dels esdeveniments publicats així com tampoc dels enllaços presents.</Typography>
        <Typography>L’Aplicació pot contenir enllaços a pàgines web i a recursos proporcionats per tercers, incloent sense caràcter exhaustiu, anunciants. No tenim cap control sobre aquestes pàgines web o recursos, ni sobre la seva disponibilitat, i qualssevol enllaços als mateixos es proporcionen únicament per a la seva informació. Necessitarà formar-se la seva pròpia opinió independent en relació amb la interacció amb aquestes pàgines web o recursos, incloent la compra i ús de qualssevol productes o serveis que estiguin disponibles a través d’aquests. No som responsables dels seus continguts ni de les seves polítiques de privacitat (si n’hi hagués) ni els recolzem, i no serem responsables de cap pèrdua o mal que pugui derivar-se del seu ús d’aquests.</Typography>
        <Typography>La bústia de contacte té caràcter merament informatiu, sense que, en cap cas, puguin derivar-se responsabilitats jurídiques vinculants en cas de resposta.</Typography> 
        <Typography>L’Aplicació està disponible de manera gratuïta, i es pot descarregar sense cap límit en qualsevol Dispositiu Mòbil.</Typography>
        <Typography>No realitzem manifestacions, ni atorguem garanties, ja siguin expresses o implícites, que l’Aplicació o qualsevol contingut d’aquesta: (i) estarà sempre disponible o lliure d’interrupcions; (ii) estarà lliure d’errors, serà exacta, completa o estarà actualitzada; o (iii) serà segura o estarà lliure d’errors o virus.</Typography>
        <Typography>L’accés a l’Aplicació es permet amb caràcter temporal, i podem suspendre, retirar, interrompre o modificar l’Aplicació, ja sigui íntegrament o en qualsevol de les seves parts, sense avisar. No serem responsables davant vostè si, per qualsevol motiu, l’Aplicació no estigués disponible en qualsevol moment o durant qualsevol període.</Typography>
        <Typography>Vostè és responsable de prendre les mesures i mitjans necessaris per al seu accés a l’Aplicació. Si vostè controla, però no és propietari, del Dispositiu Mòbil en el qual es va descarregar l’Aplicació, s’assumirà que ha obtingut permís del propietari del Dispositiu Mòbil per a fer-lo. A Vostè o al propietari del Dispositiu Mòbil els poden cobrar els seus proveïdors de serveis d’internet o telefonia per l’accés a dades a través el seu Dispositiu Mòbil. Vostè assumeix la responsabilitat de conformitat amb les presents Condicions d’Ús per l’ús de l’Aplicació o del Servei en el Dispositiu Mòbil, o en relació amb aquest, ja sigui o no de la seva propietat.</Typography>
        <Typography>Únicament, amb la finalitat d'oferir-vos un millor servei a través d'aquesta plataforma i amb l'objectiu de facilitar-ne l'ús, s'analitzen el nombre de pàgines visitades, el nombre de visites i també l'activitat i la freqüència d'utilització dels usuaris. Aquestes dades s'utilitzen amb la finalitat exclusiva d'obtenir informació estadística anònima sobre l'ús del lloc web i per controlar-ne concretament el funcionament tècnic.</Typography>
        <Typography>Podem revisar aquestes Condicions d’Ús en qualsevol moment, i cada cert temps hauria de verificar les mateixes per a prendre nota dels canvis que hàgim realitzat, ja que li vinculen legalment. També li notificarem qualssevol modificacions en el seu pròxim inici de l’Aplicació, i li requerirem llegir-les i acceptar-les per a continuar amb el seu ús del Servei. Si no està d’acord amb qualsevol de les modificacions, si us plau cessament en el seu ús de l’Aplicació, i esborri-la o elimini-la del seu Dispositiu Mòbil.</Typography>
        <Typography>La plataforma no és responsable dels danys causats per l'accés a la plataforma o per la impossibilitat d'accedir-hi; tampoc es fa responsable de la informació obtinguda a través d'enllaços a sistemes externs.</Typography>
      </Box>
      <DownloadAppFooter/>
    </Grid>
  );
};