import { Typography, Box, Backdrop, CircularProgress, CardActionArea, Card, Stack, Button} from "@mui/material";
import { useEffect, useState } from "react";
import db from '../../firebase';
import { Helmet } from 'react-helmet-async';
import { useParams } from "react-router-dom";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { MONTH_TO_TEXT } from '../../utils/Constants'
import { doc, getDoc } from 'firebase/firestore/lite';
import { DownloadAppHeader } from "../../components/DownloadAppHeader";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface dateObjectDB {
    string?:Array<string> ;
    init?: number;
    end?: number;
}

//NOTE: ALL PRINTED VALUES HAVE TO BE DEFINED ON TYPE.
interface esdevenimentDB {
    id?: string;
    title?: string;
    desc?: string;
    price?: string;
    image?: string;
    date?: dateObjectDB;
    mes_info?: string;
    url?: string;
    buy_link?: string;
    docs?: Array<string>  
    contact?: {
      whats?: string;
      tel?: string;
      email?: string;
      link?: string;
      address?: string;
      buy_link?: string;
    }
    status?: string;
    published_date?: Date;
    modified_date?: Date;
    group?: number;
    original?: boolean;
    multiDay?: boolean;
    location?: string;
    category?: string;
  }

export const EventPage = () => {
  const navigate = useNavigate();

  const { eventId } = useParams<string>();
  const [event, setEvent] = useState({} as esdevenimentDB);
  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getEvent = async () => {
        const docRef = doc(db, "events/" + encodeURI(eventId!));

        try {
            const docSnap = await getDoc(docRef);
            const data = docSnap.data()
            if (data) {
                setEvent(data);
            } else {
                setNotFound(true);
            }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    
    getEvent();
  }, [eventId]);

  const printDate = (unixDate: number) => {
      var date = new Date(unixDate * 1000);
      return date.getDate()  + " " + (MONTH_TO_TEXT[date.getMonth()]) + " de " + date.getFullYear() +
      " a les " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + "h";
  }

  const printDateMultiDay = (unixStartDate: number, unixEndDate: number) => {
      var startDate = new Date(unixStartDate * 1000);
      var endDate = new Date(unixEndDate * 1000);
      return "Del " + startDate.getDate()  + " " + (MONTH_TO_TEXT[startDate.getMonth()]) + " de " + startDate.getFullYear() +
      " al " + endDate.getDate()  + " " + (MONTH_TO_TEXT[endDate.getMonth()]) + " de " + endDate.getFullYear()
  }

  const goToLink = (link: string) => {
      let pattern = /^((http|https|ftp):\/\/)/;
      let url ="";
      if(!pattern.test(link)) {
          url = "http://" + link;
      }
      window.open(url, "_blank")
  }

  const isNum = (num: string) => /^\d*\.?\d*$/.test(num);

  return (
    <Box>
      {isLoading ? (
            <Backdrop open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        ) : notFound ? (
            <Typography>Esdeveniment no trobat</Typography>
        ) : (
        <Grid container spacing={2}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{event.title}</title>
              <meta
                name="description"
                content={event.desc}
              />
              <meta
                name="keywords"
                content={`${event.category}, andorra, activitat, esdeveniment, evento, event, ${event.title}`}
              />
          </Helmet>
          <DownloadAppHeader />
          <Grid xs={12} mdOffset={1} md={10}>
            <Stack direction="column" spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Button variant="outlined" startIcon={<CalendarMonthIcon />} onClick={() => navigate(`/calendari`)} sx={{color: "darkorchid", borderColor: "darkorchid", "&:hover": { borderColor: "darkorchid" }}}>
                  Tornar al calendari
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12} sm={6} md={4} mdOffset={1} sx={{px:{xs:0, sm: 2}, py: 0, alignItems: "center", justifyContent: "center"}}>
            <img src={event.image} width="100%" alt="event image"/>
          </Grid>
          <Grid xs={12} sm={6} md={6} sx={{py: 0, px:{xs:2}}}>
            <Typography variant="h6">{event.title}</Typography>
            {event.date && event.date.init && event.multiDay ? 
              (printDateMultiDay(event.date.init, event.date.end!)) :
              event.date && event.date.init && !event.multiDay &&
              (printDate(event.date.init))
            }
            <Card sx={{px: 1, py: 2, border: 1, my:1}}>
              <CardActionArea onClick={()=> window.open(`https://maps.google.com/?q=${event.location}`, "_blank")} >
                <Stack  
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>{event.location}</Typography> 
                    <RoomOutlinedIcon />
                </Stack>
              </CardActionArea>
            </Card>

            {event.contact && event.contact.buy_link ? (
              <Card sx={{px: 1, py: 2, border: 1,my:1}}>
                <CardActionArea onClick={()=> goToLink(event.contact!.buy_link!)} >
                  <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>Comprar entrades</Typography> 
                      <OpenInNewOutlinedIcon />
                  </Stack>
                </CardActionArea>
              </Card>
            ):(
              <Box>
                <Typography sx={{my:1}}>{isNum(event.price!) && parseInt(event.price!) > 0 ? "Preu: " + Number(event.price!.replace(/[^0-9\.-]+/g,"")) + "€" : ((isNum(event.price!) && parseInt(event.price!) === 0) || event.price === undefined || event.price === "") ? "Activitat gratuïta" : event.price !== undefined && event.price !== "" && event.price}</Typography> 
              </Box>
            )}
            {event.desc &&
              <Typography sx={{whiteSpace: "pre-wrap"}}>{event.desc && event.desc.replace(/\\n/g,'\n\n')}</Typography> 
            }
            {event.contact && (event.contact.tel || event.contact.email || event.contact.link) &&
                <Card sx={{px: 1, py: 2, border: 1, my:2, display:'flex', justifyContent:'center'}}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    width="100%"
                    spacing={2}
                  >
                    <Typography>Contacta</Typography> 
                    <Stack
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                      width="100%"
                    >
                      {event.contact.tel && <CallOutlinedIcon onClick={()=> window.open(`tel:${event.contact!.tel}`)}/>}
                      {event.contact.email && <MailOutlineOutlinedIcon onClick={()=> window.open(`mailto:${event.contact!.email}?subject=${event.title}`)}/>}
                      {event.contact.link && <InfoOutlinedIcon onClick={()=>goToLink(event.contact!.link!)}/>}
                    </Stack>
                  </Stack>
                </Card>
            }
          </Grid>
          <Grid xs={12} sx={{mt:3}}>
            <Stack   spacing={2} sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
                <Typography align='center' variant="h4">Vols assabentar-te de tooooooot?</Typography>
                <Typography align='center'>Música, espectacles, activitats infantils, exposicions, conferències, fires i mercats, festes i tradicions, teatre, dansa, esdeveniments esportius i molt més.</Typography>
                <ArrowDownwardIcon fontSize="large"/>
            </Stack>
          </Grid>
          <DownloadAppFooter/>
        </Grid>
        )}
    </Box>
  );
};
