import { Typography, Box, Stack, Card, Link} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";


export const DownloadAppFooter = () => {

const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }
    return "other";
}

  return (
    <Grid xs={10} xsOffset={1} sm={6} smOffset={3} md={4} mdOffset={4} sx={{py:{xs:0, sm: 2}, px:{xs:2}}}>
        <Card sx={{px: 1, py: 2, border: 1, my:1,  display:'flex', justifyContent:'center', backgroundColor: 'text.primary'}}>
            <Stack
            direction="column"
            alignItems="center"
            width="100%"
            spacing={2}
            >
            <Typography sx={{color: 'primary.contrastText'}} align="center">Descarrega la APP per estar al dia de tots els esdeveniments a Andorra</Typography> 
            <Box
                component="img"
                sx={{
                width: {xs:"50%", sm: "30%", md: "30%"},
                borderRadius: 5
                }}
                src="/app_icon_512.png" 
            />
            <Typography sx={{color: 'primary.contrastText', fontWeight: 'bold'}} align="center">QuedAND</Typography> 
            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                width="100%"
            >
                {(getMobileOperatingSystem() === "iOS" || getMobileOperatingSystem() === "other") && 
                    <Link href={IOS_DOWNLOAD_LINK}>
                        <Box
                        component="img"
                        sx={{
                            width: "150px",
                        }}
                        src="/app_store_ca.png" 
                        />
                    </Link>
                }
                {(getMobileOperatingSystem() === "Android" || getMobileOperatingSystem() === "other") && 
                    <Link href={ANDROID_DOWNLOAD_LINK}>
                        <Box
                        component="img"
                        sx={{
                            width: "150px",
                        }}
                        src="/google_play_ca.png" 
                        />
                    </Link>
                }
            </Stack>
            </Stack>
        </Card>
    </Grid>
  );
};