import {Typography, Box }from "@mui/material";
import { DownloadAppHeader } from "../../components/DownloadAppHeader";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export const PoliticaPrivacitatPage = () => {
  return (
    <Grid container spacing={2}>
        <Grid xs={12} smOffset={1} sm={10} sx={{mt:2}}>
            <Typography variant="h4" sx={{my:1}}>Política de privacitat</Typography>
            <Typography>La present política de privacitat regula dita política en relació a l’ús i accés al lloc web www.quedand.com i la corresponent aplicació mòbil, titularitat de Marc Camp.</Typography>
            <Typography>Com obtenim les seves dades?</Typography>
            <Typography variant="h6" sx={{my:1}}>Obtenim informació:</Typography>
            <Typography>Només quan contacta amb nosaltres directament a través de correu electrònic, telèfon o missatge i cada vegada que navega i utilitza una de les nostres pàgines.</Typography>
            <Typography variant="h6" sx={{my:1}}>Dades que obtenim sobre vostè</Typography>
            <Typography>Com a part del nostre compromís amb la protecció de les dades dels nostres usuaris volem ser transparents respecte dels tipus de dades que obtindrem sobre vostè.</Typography>
            <Typography>També obtenim informació sobre l’ús que vostè fa de la plataforma, o cada vegada que vostè contacta amb nosaltres; això inclou correu electrònic o trucades telefòniques. Recaptem informació tècnica sobre el seu ordinador o el seu dispositiu mòbil, com el seu sistema operatiu i tipus de dispositiu des de la qual vostè accedeix a la nostra plataforma.</Typography>
            <Typography variant="h6" sx={{my:1}}>Ús de dades</Typography>
            <Typography>No utilitzem les seves dades per res. Si ens contacta, li podem respondre. En relació a les dades sobre l'ús de la plataforma, són tractades com a dades estadístiques.</Typography>
            <Typography variant="h6" sx={{my:1}}>Cookies</Typography>
            <Typography>L’Aplicació i el nostre lloc web pot utilitzar cookies per a diferenciar-li d’altres usuaris. Això ens ajuda a proporcionar-li una bona experiència quan utilitza l’Aplicació o el nostre lloc web, i també ens permet millorar-los. Les cookies s’utilitzen per a: Mesura l’ús que li dónes a la web perquè pugui ser actualitzada i millorada basada en les teves necessitats. Si ho desitja, pot configurar el seu navegador per a rebutjar totes o algunes de les cookies de navegació, o per a avisar-li cada vegada que una pàgina web posa en marxa cookies o accedir a elles. Si inhabilita o rebutja les cookies, tingui en compte que algunes seccions de la plataforma podrien no ser accessibles o no funcionar correctament.</Typography>
            <Typography> variant="h6"Conservació de les seves dades</Typography>
            <Typography>Ens limitarem a conservar la informació relativa a vostè estrictament el temps necessari.</Typography>
            <Typography variant="h6" sx={{my:1}}>Revelació de les seves dades</Typography>
            <Typography>La informació que obtenim sobre vostè es transferirà als nostres servidors i quedarà emmagatzemada en aquests; aquests servidors es troben a la Unió Europea. Actuem amb total responsabilitat i transparència sobre amb qui més compartim aquesta informació.</Typography>
            <Typography variant="h6" sx={{my:1}}>Canvis en aquesta Política de Privacitat</Typography>
            <Typography>Sempre que introduïm canvis en la nostra política de protecció de dades, aquests apareixeran en la plataforma i, en el seu cas, els hi notificarem, per exemple, per correu electrònic o mitjançant notificació automàtica.</Typography>
            <Typography variant="h6" sx={{my:1}}>Contacte</Typography>
            <Typography>Agraïm rebre preguntes, comentaris i sol·licituds en relació amb la present política de privacitat, i les mateixes se’ns han d’enviar mitjançant correu electrònic dirigit quedand.app@gmail.com</Typography>
        </Grid>
      <DownloadAppFooter/>
    </Grid>
  );
};