import {Typography, Stack, Box, Link } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { DownloadAppHeader } from "../../components/DownloadAppHeader";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";
import { useTypeWriter } from "../../hooks/useTypeWriter";
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export const HomePage = () => {
  const displayText = useTypeWriter();

  return (
    <Grid container spacing={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>QuedAND - Tot el que passa a Andorra en un sol lloc</title>
          <meta
                name="description"
                content="Activitats, esdeveniments, concerts, música, cultura, fires a Andorra i molt més. Tot el que passa a Andorra en un sol lloc."
          />
          <meta
            name="keywords"
            content={`andorra, activitats, esdeveniments, eventos, events`}
          />
      </Helmet>
      <DownloadAppHeader />
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        spacing={2}
        sx={{my:4}}
      >
        <Typography variant="h4" align="center">Tot el que passa a Andorra en un sol lloc.</Typography>
        {displayText === "" ? <Typography variant="h4" sx={{color: 'primary.contrastText'}}>.</Typography> : <Typography variant="h4">{displayText}</Typography>}
        <Typography variant="h5" align="center">Descarrega QuedAND</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          spacing={2}
          sx={{my:4}}
        >
          <Link href={IOS_DOWNLOAD_LINK}>
            <Box
              component="img"
              sx={{
                width: "150px",
              }}
              src="/app_store_ca.png" 
            />
          </Link>
          <Link href={ANDROID_DOWNLOAD_LINK}>
            <Box
              component="img"
              sx={{
                width: "150px",
              }}
              src="/google_play_ca.png" 
            />
          </Link>
        </Stack>
        <Box
          component="img"
          sx={{
          width: {xs:"75%", sm:"50%", md:"25%"},
          }}
          src="/inici-portrait_low.png" 
        />
        <Typography variant="h4" align="center">Tots els detalls.</Typography>
        <Box
          component="img"
          sx={{
          width: {xs:"75%", sm:"50%", md:"25%"},
          }}
          src="/detall-portrait_low.png" 
        />
        <DownloadAppFooter />
      </Stack>
    </Grid>
  );
};
