import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";

export const AppPage = () => {
    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            window.open(ANDROID_DOWNLOAD_LINK);
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.open(IOS_DOWNLOAD_LINK);
        } else {
            window.open("/");
        } 
    }
    getMobileOperatingSystem()
  return <DownloadAppFooter/>;
};