import {Typography, Button, Stack}from "@mui/material";
import { DownloadAppHeader } from "../../components/DownloadAppHeader";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { CONTACT_EMAIL } from "../../utils/Constants";

export const SupportPage = () => {
  return (
    <Grid container spacing={2}>
      <DownloadAppHeader />
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        spacing={2}
        sx={{my:4}}
      >
        <Typography variant="h5" align="center">Tens algun dubte?</Typography>
        <Button onClick={()=> window.open(`mailto:${CONTACT_EMAIL}?subject=Hola!`)} variant="contained" color="secondary">Contacta amb nosaltres</Button>
      </Stack>
      <DownloadAppFooter/>
    </Grid>
  );
};