import { MONTH_TO_TEXT } from "./Constants";

export function printDate(unixDate: number) {
    var date = new Date(unixDate * 1000);
    return date.getDate()  + " " + (MONTH_TO_TEXT[date.getMonth()]) + " de " + date.getFullYear() +
    " a les " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + "h";
}


export function printDateMultiDay(unixStartDate: number, unixEndDate:number) {
    var startDate = new Date(unixStartDate * 1000);
    var endDate = new Date(unixEndDate * 1000);
    return "Del " + startDate.getDate()  + " " + (MONTH_TO_TEXT[startDate.getMonth()]) + " de " + startDate.getFullYear() +
    " al " + endDate.getDate()  + " " + (MONTH_TO_TEXT[endDate.getMonth()]) + " de " + endDate.getFullYear()
}