import { useState, useEffect } from "react";

export const useTypeWriter = () => {
    const SPEED = 100;
    const [forward, setForward] = useState(true);
    const [displayText, setDisplayText] = useState('');
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);


  
    useEffect(() => {
        const WORDS = ["Activitats", "Esdeveniments", "Concerts", "Exposicions", "Activitats infantils", "I molt més"]

      const typingInterval = setInterval(() => {
        if (currentWordIndex <= WORDS.length-1) {
            if (currentIndex <= WORDS[currentWordIndex].length) {
                if (forward) {
                  setDisplayText(prevText => prevText + WORDS[currentWordIndex].charAt(currentIndex));
                  setCurrentIndex(currentIndex + 1)
                } else if (!forward) {
                  setDisplayText(prevText => prevText.substring(0, prevText.length - 1));
                  setCurrentIndex(currentIndex - 1)
                }
                if (currentIndex === 0 && !forward) {
                    setCurrentWordIndex(currentWordIndex+1)
                    setForward(!forward)
                }
            } else {
                setForward(!forward)
                setCurrentIndex(WORDS[currentWordIndex].length)
            
            }
        } else {
            setCurrentWordIndex(0);
        }
        
      }, SPEED);
  
      return () => {
        clearInterval(typingInterval);
      };
    }, [SPEED, forward,currentWordIndex, currentIndex]);
  
    return displayText;
  };