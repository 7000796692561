import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';
import {Typography, Box, Stack, CircularProgress, IconButton, Link} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import db from '../../firebase';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { printDate, printDateMultiDay } from '../../utils/DateParser';
import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { DownloadAppFooter } from '../../components/DownloadAppFooter';
import { DownloadAppHeader } from '../../components/DownloadAppHeader';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";

export const CalendariPage = () => {
    const navigate = useNavigate();
    interface esdevenimentDB {
        id?: string;
        title?: string;
        desc?: string;
        image?: string;
        price?: string;
        location?:string;
        category?: string;
        date?: dateObjectDB;
        mes_info?: string;
        info?: string;
        url?: string;
        buy_link?: string;
        docs?: Array<string>  
        contact?: {
            whats?: string;
            tel?: string;
            email?: string;
            link?: string;
            address?: string;
            buy_link?: string;
        }
        status?: string;
        published_date?: Date;
        modified_date?: Date;
        group?: number;
        original?: boolean;
        multiDay?: boolean;
        }

    interface dateObjectDB {
    string?:Array<string> ;
    init?: number;
    end?: number;
    }

    const [events, setEvents] = useState<esdevenimentDB[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [OS, setOS] = useState("");
    const [daysOfPrintedWeek, setDaysOfPrintedWeek] = useState<Date[]>([]);

    const DIES: {[id: string] : string }= {
        0: "DLL",
        1: "DM",
        2: "DX",
        3: "DJ",
        4: "DV",
        5: "DSS",
        6: "DU"
      }

      const SHORT_MONTHS_DICTIONARY: {[id: string] : string } = {
        0: "Gen",
        1: "Feb",
        2: "Mar",
        3: "Abr",
        4: "Mai",
        5: "Jun",
        6: "Jul",
        7: "Ago",
        8: "Set",
        9: "Oct",
        10: "Nov",
        11: "Des"
    }

    const startDay = 1; //0=sunday, 1=monday etc.
    const now = new Date()
    const d = now.getDay()
    const weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay : d-startDay )*86400000); //rewind to start day
    const weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day

    const [selectedDay, setSelectedDay] = useState(new Date(now.setHours(0, 0, 0, 0)));

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "iOS";
        }
        return "other";
    }

    useEffect(() => {
        if (daysOfPrintedWeek.length === 0) {
            const days = [];
            for (let i = 1; i < 8; i++) {
              days.push(new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() - weekStart.getDay() + i))
            }
            setDaysOfPrintedWeek(days) 
        }

    }, [weekEnd]);

    useEffect(() => {
        const now00 = new Date(now.setHours(0, 0, 0)).getTime()/1000
        const now23 = new Date(now.setHours(23, 59, 59)).getTime()/1000
        const getEventsMultiDay = async () => {
            try {
                const eventsCol = query(collection(db, "events"), where("date.end", ">=", now00), where("status", "==", "PUBLISHED"),where("multiDay", "==", true));
                const eventsSnapshot = await getDocs(eventsCol);
                const eventsList = eventsSnapshot.docs.map(doc => doc.data());
                setEvents(prevEvents => [...prevEvents,...eventsList] );
                setIsLoading(false)
            } catch (error) {
                console.log(error)
            }
        };
        const getEventsNoMultiDay = async () => {
            try {
              const eventsCol = query(collection(db, "events"), where("date.init", ">=", now00), where("date.init", "<=", now23), where("status", "==", "PUBLISHED"),where("multiDay", "==", false));
              const eventsSnapshot = await getDocs(eventsCol);
              const eventsList = eventsSnapshot.docs.map(doc => doc.data());
              setEvents(eventsList)
              getEventsMultiDay();
            } catch (error) {
              console.log(error)
            } finally {
              setIsLoading(false);
            }
        };
        
        getEventsNoMultiDay()
        getMobileOperatingSystem()
      }, []);

      function isToday(day: Date) {
        if (now !== undefined && day !== undefined) {
          return day.getFullYear() === now.getFullYear() && day.getMonth() === now.getMonth() && day.getDate() === now.getDate();
        }
      }

      const dayClick = (_e:React.MouseEvent<HTMLElement>, day: Date) => {
        setSelectedDay(day);
      };


    function printDateHeader(unixDate: Date) {
        var date = new Date(unixDate);
        return `${date.getDate()} ${SHORT_MONTHS_DICTIONARY[date.getMonth()]}.`
    }

    console.log(OS)

    function printSelectedDay(day:Date) {
        return day.getDate() === selectedDay.getDate();
    }

  return (
    <Box>
        <DownloadAppHeader />
        <Grid container spacing={2} sx={{mb:2}}>
            <Grid xs={12} smOffset={1} sm={10} mdOffset={2} md={8}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: "center", alignItems: "center", my: 1 }}>
                    <Typography>
                        {daysOfPrintedWeek[0] && printDateHeader(daysOfPrintedWeek[0])} - {daysOfPrintedWeek[6] && printDateHeader(daysOfPrintedWeek[6])}
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12} smOffset={1} sm={10} mdOffset={2} md={8}>
                <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around", alignItems: "center" }}>
                    {daysOfPrintedWeek.map((day, index) => (
                        <Box key={index} onClick={(e) => dayClick(e, day)} sx={{width: 1, cursor: 'pointer', "&:hover": { backgroundColor: grey[200] }}} >
                            <Stack direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{color: isToday(day) ? "darkorchid" : "black"}}>{DIES[index]}</Typography>
                                <Typography>{day.getDate()}</Typography>
                                {printSelectedDay(day) && <Box sx={{borderColor:  grey[800], borderBottom: 2, width: 1}} />}
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Grid>
        </Grid>
        {isLoading ? 
            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", width: 1 }}>
                <CircularProgress />
            </Box>
        : isToday(selectedDay) ?
            events.map((event) => (
                <Box sx={{w:1}} key={event.id} onClick={() => navigate(`/activitat/${event.id!}`)}>
                    <Grid container spacing={2} sx={{ cursor: 'pointer', "&:hover": { backgroundColor: grey[200] } }}>
                        <Grid xs={12} smOffset={1} sm={5} mdOffset={2} md={4} sx={{px:{xs:0, sm: 2}, py:{xs:0, sm: 3}}}>
                            <Stack direction="column" spacing={2} sx={{ justifyContent: "center", alignItems: "flex-start", height: 1 }}>
                                <Box
                                    component="img"
                                    sx={{ 
                                        objectFit: 'cover',
                                        width: "100%",
                                        maxHeight: {xs: "150px", sm: "250px", md: "350px"}
                                    }}
                                    src={event.image} 
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={5} md={4} sx={{py:{xs:0, sm: 2}, px:{xs:2}}}>
                            <Stack direction="column" spacing={2} sx={{ justifyContent: "center", alignItems: "flex-start", height: 1 }}>
                                <Typography variant="body2">{event.category}</Typography>
                                <Typography variant="h6" sx={{mt:0}}>{event.title}</Typography>
                                <Typography sx={{mt:0}}>{event.multiDay ? printDateMultiDay(event.date!.init!, event.date!.end!) : printDate(event.date!.init!)} </Typography> 
                                <Typography sx={{mt:0}}>{event.contact && event.contact.address ? event.contact.address : event.location} </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} key={event.id}>
                        <Grid xs={12} smOffset={1} sm={10} mdOffset={2} md={8} sx={{my: 2}}>
                            <Box sx={{width:1, borderBottom: {xs:0, sm:1}}}></Box>
                        </Grid>
                    </Grid>
                </Box>
            ))
        : 
            <Grid container spacing={2} sx={{mb:2}}>
                <Grid xs={12} smOffset={1} sm={10} mdOffset={2} md={8}>
                    <Stack direction="column" spacing={2} sx={{ justifyContent: "center", alignItems: "flex-start", height: 1 }}>
                        <Typography variant="h4" align='center'>Ups! T'has de baixar la APP per veure les activitats 😃</Typography>
                    </Stack>
                </Grid>
            </Grid>
        }
        <Stack   spacing={2} sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
            {isToday(selectedDay) && <Typography align='center' variant="h4">Vols assabentar-te de tooooooot?</Typography>}
            <Typography align='center'>Música, espectacles, activitats infantils, exposicions, conferències, fires i mercats, festes i tradicions, teatre, dansa, esdeveniments esportius i molt més.</Typography>
            <ArrowDownwardIcon fontSize="large"/>
        </Stack>
        <Grid container>
            <DownloadAppFooter/>
        </Grid>
    </Box>
  ) 
};