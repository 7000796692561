import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';
import {Typography, Box, Stack, CircularProgress, ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import db from '../../firebase';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { printDate, printDateMultiDay } from '../../utils/DateParser';
import { MONTH_TO_TEXT } from '../../utils/Constants';
import { grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { DownloadAppFooter } from '../../components/DownloadAppFooter';
import { DownloadAppHeader } from '../../components/DownloadAppHeader';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";

export const AvuiPage = () => {
    const navigate = useNavigate();
    interface esdevenimentDB {
        id?: string;
        title?: string;
        desc?: string;
        image?: string;
        price?: string;
        location?:string;
        category?: string;
        date?: dateObjectDB;
        mes_info?: string;
        info?: string;
        url?: string;
        buy_link?: string;
        docs?: Array<string>  
        contact?: {
            whats?: string;
            tel?: string;
            email?: string;
            link?: string;
            address?: string;
            buy_link?: string;
        }
        status?: string;
        published_date?: Date;
        modified_date?: Date;
        group?: number;
        original?: boolean;
        multiDay?: boolean;
        }

    interface dateObjectDB {
    string?:Array<string> ;
    init?: number;
    end?: number;
    }

    const [events, setEvents] = useState<esdevenimentDB[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [OS, setOS] = useState("");
    const [daysOfPrintedWeek, setDaysOfPrintedWeek] = useState<Date[]>([]);

    const DIES: {[id: string] : string }= {
        0: "DLL",
        1: "DM",
        2: "DX",
        3: "DJ",
        4: "DV",
        5: "DSS",
        6: "DU"
      }

      const SHORT_MONTHS_DICTIONARY: {[id: string] : string } = {
        0: "Gen",
        1: "Feb",
        2: "Mar",
        3: "Abr",
        4: "Mai",
        5: "Jun",
        6: "Jul",
        7: "Ago",
        8: "Set",
        9: "Oct",
        10: "Nov",
        11: "Des"
    }

    const startDay = 1; //0=sunday, 1=monday etc.
    const now = new Date()
    const d = now.getDay()
    const weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay : d-startDay )*86400000); //rewind to start day
    const weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day

    const [selectedDay, setSelectedDay] = useState(new Date(now.setHours(0, 0, 0, 0)));

    useEffect(() => {
        if (daysOfPrintedWeek.length === 0) {
            const days = [];
            for (let i = 1; i < 8; i++) {
              days.push(new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() - weekStart.getDay() + i))
            }
            setDaysOfPrintedWeek(days) 
        }

    }, [weekEnd]);

    useEffect(() => {
        const now00 = new Date(now.setHours(0, 0, 0)).getTime()/1000
        const now23 = new Date(now.setHours(23, 59, 59)).getTime()/1000
        const getEventsMultiDay = async () => {
            try {
                const eventsCol = query(collection(db, "events"), where("date.end", ">=", now00), where("status", "==", "PUBLISHED"),where("multiDay", "==", true));
                const eventsSnapshot = await getDocs(eventsCol);
                const eventsList = eventsSnapshot.docs.map(doc => doc.data());
                setEvents(prevEvents => [...prevEvents,...eventsList] );
                setIsLoading(false)
            } catch (error) {
                console.log(error)
            }
        };
        const getEventsNoMultiDay = async () => {
            try {
              const eventsCol = query(collection(db, "events"), where("date.init", ">=", now00), where("date.init", "<=", now23), where("status", "==", "PUBLISHED"),where("multiDay", "==", false));
              const eventsSnapshot = await getDocs(eventsCol);
              const eventsList = eventsSnapshot.docs.map(doc => doc.data());
              setEvents(eventsList)
              getEventsMultiDay();
            } catch (error) {
              console.log(error)
            } finally {
              setIsLoading(false);
            }
        };
        
        getEventsNoMultiDay()
      }, []);

      const printDate = () => {
        const date = new Date(); // Or any Date('YYYY-MM-DD')
        return date.getDate()  + " " + (MONTH_TO_TEXT[date.getMonth()]) + " de " + date.getFullYear()
    }

  return (
    <Box>
        <DownloadAppHeader />
        {isLoading ? 
            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", width: 1 }}>
                <CircularProgress />
            </Box>
        : 
        <Box>
            <Typography variant="h4" align="center" sx={{my:1}}>{printDate()}</Typography>
            <ImageList variant="masonry" cols={3} gap={8}>
                {
                    events.map((event) => (
                        <ImageListItem key={event.id}>
                <img
                srcSet={`${event.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${event.image}?w=248&fit=crop&auto=format`}
                alt={event.title}
                loading="lazy"
                />
                <ImageListItemBar
            title={event.title}
            subtitle={event.category}
            sx={{
                
                "& .MuiImageListItemBar-titleWrap": { pt: 0 },
                "& .MuiImageListItemBar-title": { fontSize: "12px" }, //styles for title
                "& .MuiImageListItemBar-subtitle": { fontSize: "8px" }, //styles for title

              }}
          />
            </ImageListItem>
                        
                    ))
                }
            </ImageList>
        </Box>
        
        }
        <Stack   spacing={2} sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
            <Typography align='center' variant="h4">Vols assabentar-te de tooooooot?</Typography>
            <Typography align='center'>Música, espectacles, activitats infantils, exposicions, conferències, fires i mercats, festes i tradicions, teatre, dansa, esdeveniments esportius i molt més.</Typography>
            <ArrowDownwardIcon fontSize="large"/>
        </Stack>
        <Grid container>
            <DownloadAppFooter/>
        </Grid>
    </Box>
  ) 
};