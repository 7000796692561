import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyAV8YHYiw7unUaDaXM0Pi9qCkUmgiMa9BU",
  authDomain: "agendandorra.firebaseapp.com",
  databaseURL: "https://agendandorra.firebaseio.com",
  projectId: "agendandorra",
  storageBucket: "agendandorra.appspot.com",
  messagingSenderId: "475587677191",
  appId: "1:475587677191:web:a08f273f9530abf2552ea2"

};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;