import { Typography, Box, Stack} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";


export const DownloadAppHeader = () => {

const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }
    return "other";
}

  return (
    <Grid xs={12} sx={{pb:0, borderBottom: 1,width:"100%"}}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{p:1, backgroundColor: 'text.primary'}}
        >
            <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
            >
                <Box
                    component="img"
                    sx={{
                    width: {xs:"50px"},
                    borderRadius: 3
                    }}
                    src="/app_icon_512.png" 
                />
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Typography sx={{ color: 'primary.contrastText'}}>QuedAND</Typography> 
                    <Typography variant="caption" sx={{mt:0, color: 'primary.contrastText'}} >Esdeveniments a Andorra</Typography> 
                </Stack>
            </Stack>
            <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
            >
                {(getMobileOperatingSystem() === "other" || getMobileOperatingSystem() === "iOS") && 
                    <Typography variant="caption" onClick={()=> window.open(IOS_DOWNLOAD_LINK, "_blank")} sx={{ fontWeight: 'bold', textDecoration: 'underline', color: 'primary.contrastText'}}>{getMobileOperatingSystem() === "iOS" ? "Obrir a la app" : "iPhone App"}</Typography>
                }
                {(getMobileOperatingSystem() === "other" || getMobileOperatingSystem() === "Android") && 
                    <Typography variant="caption" onClick={()=> window.open(ANDROID_DOWNLOAD_LINK, "_blank")} sx={{ fontWeight: 'bold', textDecoration: 'underline', color: 'primary.contrastText'}}>{getMobileOperatingSystem() === "iOS" ? "Obrir a la app" : "Google App"}</Typography>
                }
            </Stack>
            
        </Stack>
    </Grid>
  );
};